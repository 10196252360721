import * as React from "react"
import { useRouter } from "next/router"
import dayjs, { Dayjs } from "dayjs"
import arraySupport from "dayjs/plugin/arraySupport"
import { useMutation, useQuery } from "react-query"
import { BUSINESS_HOURS_INITIAL, WEEK_DAYS } from "../constants"
import {
  B_ACTIVE,
  BUSINESS_HOUR_TYPE,
  B_OP,
  INITIAL_CALENDAR_DATA,
  InputChangeProps,
  INITIAL_CALENDAR_DATA_EXTRA,
  BusinessDayContextProps,
} from "../types"
import {
  scrollToSelectedElement,
  useNotification,
  useUpdateSearchParams,
} from "@project/common"
import { fetchBusinessDayRegistration, registerBusinessDay } from "../services"
import { useTranslation } from "react-i18next"
import {
  handleMonthlyDataApplied,
  handleYearlyDataApplied,
  serializeMonthlyBusinessDayManagementDataPayload,
  serializeMonthlyBusinessDayManagementDataResponse,
  serializeYearlyBusinessDayManagementDataPayload,
  serializeYearlyBusinessDayManagementDataResponse,
} from "../serialize"
import { AuthContext } from "./AuthContext"
dayjs.extend(arraySupport)

const BusinessDayManagementContext = React.createContext<
  BusinessDayContextProps | undefined
>(undefined)

const BusinessDayManagementProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const router = useRouter()
  const { t } = useTranslation()
  const listRef = React.useRef<HTMLDivElement>(null)
  const initialHours = BUSINESS_HOURS_INITIAL
  const { facilityId, year, month, is_set, facility_name, tab } =
    router?.query as any
  const { showToast } = useNotification()
  const { facilities } = React.useContext(AuthContext)
  const [updateParams] = useUpdateSearchParams()
  const [activeTab, setActiveTab] = React.useState<B_ACTIVE>(
    tab || "bulk_setting_for_year",
  )

  //operation options start here
  const [searchParams, serSearchParams] = React.useState({
    facility_id: facilityId,
    date: dayjs([year, month]).isValid() ? dayjs([year, +month - 1]) : dayjs(),
  })
  const [operationOptionsValues, setOperationOptionsValues] = React.useState({
    params: {
      ...searchParams,
    },
    settings: {
      holidays: [],
      business_days: [],
      meal: "0",
      business_hours1: initialHours,
      business_hours2: initialHours,
      business_hours3: initialHours,
      service_hours1: initialHours,
      service_hours2: initialHours,
      service_hours3: initialHours,
    },
  })
  const updateSearchParams = (date: Dayjs) => {
    const params = {
      facilityId,
      year: dayjs(date).year(),
      month: dayjs(date).month() + 1,
      facility_name: facility_name,
      is_set: is_set,
    }
    updateParams(params, "/business-day-management/registration")
    scrollToSelectedElement(listRef)
  }
  const handleOperationInputChange = ({ type, key, value }: B_OP) => {
    setOperationOptionsValues({
      ...operationOptionsValues,
      [type]: {
        ...operationOptionsValues[type],
        [key]: value,
      },
    })
  }
  const handleOperationInputHourChange = ({
    type,
    key,
    value,
    range,
    unit,
  }: B_OP) => {
    setOperationOptionsValues({
      ...operationOptionsValues,
      [type]: {
        ...operationOptionsValues[type],
        [key]: {
          ...operationOptionsValues[type][key],
          [range]: {
            ...operationOptionsValues[type][key][range],
            hours:
              unit === "hours"
                ? value
                : operationOptionsValues[type][key][range]["hours"],
            minutes:
              unit === "minutes"
                ? value
                : value
                  ? operationOptionsValues[type][key][range]["minutes"] || "00"
                  : "",
          },
        },
      },
    })
  }

  const onDisplayChangeBtnClicked = () => {
    serSearchParams(operationOptionsValues?.params)
    updateSearchParams(operationOptionsValues?.params?.date)
    setMonthlyCalendarData((prev) => ({
      ...prev,
      override_facilities: [operationOptionsValues?.params?.facility_id],
    }))
  }
  //operation options end here
  const [originalData, setOriginalData] = React.useState<any>({
    yearly: [],
    monthly: [],
  })
  //yearly calendar initial data
  const yearlyInitialValues = {}
  WEEK_DAYS?.map((days) => {
    yearlyInitialValues[`${days?.key}`] = [
      {
        id: 1,
        ...initialHours,
      },
    ]
  })
  const [yearlyCalendarData, setYearlyCalendarData] =
    React.useState<INITIAL_CALENDAR_DATA | null>({
      business_hours: yearlyInitialValues || {},
      service_hours: yearlyInitialValues || {},
      time_setting: "1",
      day_hours: null,
      day_minutes: null,
      week_hours: null,
      week_minutes: null,
      holidays: [],
    })
  //monthly calendar initial data
  const monthInitialValues = {}
  Array.from(
    {
      length: dayjs(operationOptionsValues?.params?.date).daysInMonth() || 31,
    },
    (_, idx) => {
      monthInitialValues[`${idx + 1}`] = [
        {
          id: 1,
          ...initialHours,
        },
      ]
    },
  )
  const [monthlyCalendarData, setMonthlyCalendarData] = React.useState<
    (INITIAL_CALENDAR_DATA & INITIAL_CALENDAR_DATA_EXTRA) | null
  >({
    business_hours: monthInitialValues || {},
    service_hours: monthInitialValues || {},
    time_setting: "1",
    day_hours: null,
    day_minutes: null,
    week_hours: null,
    week_minutes: null,
    holidays: [],
    modified_working: "0",
    occupations: [],
    override_facilities: [facilityId],
    modified_working_start_day: null,
    modified_working_end_day: null,
    modified_working_hours: null,
    modified_working_minutes: null,
    meal: null,
  })
  const handleAddition = (
    type: BUSINESS_HOUR_TYPE,
    addFor?: string,
    data?: any,
  ) => {
    return {
      ...data,
      [type]: {
        ...data[type],
        [addFor]: [
          ...data[type][addFor],
          {
            id: data[type][addFor]?.length + 1,
            ...initialHours,
          },
        ],
      },
    }
  }
  const handleDataAddition = (type: BUSINESS_HOUR_TYPE, addFor?: string) => {
    if (activeTab === "bulk_setting_for_year") {
      const newData = handleAddition(type, addFor, yearlyCalendarData || {})
      setYearlyCalendarData(newData)
    }
    if (activeTab === "bulk_setting_for_month") {
      const newData = handleAddition(type, addFor, monthlyCalendarData || {})
      setMonthlyCalendarData(newData)
    }
  }

  const handleDataRemove = (type: BUSINESS_HOUR_TYPE, key: string | number) => {
    if (activeTab === "bulk_setting_for_year") {
      const data = yearlyCalendarData[type][key]?.splice(-1)[0]
      const newData = yearlyCalendarData[type][key]?.filter(
        (v) => v.id !== data.id,
      )
      setYearlyCalendarData({
        ...yearlyCalendarData,
        [type]: {
          ...yearlyCalendarData[type],
          [key]: newData,
        },
      })
    }
    if (activeTab === "bulk_setting_for_month") {
      const data = monthlyCalendarData[type][key]?.splice(-1)[0]
      const newData = monthlyCalendarData[type][key]?.filter(
        (v) => v.id !== data.id,
      )
      setYearlyCalendarData({
        ...monthlyCalendarData,
        [type]: {
          ...monthlyCalendarData[type],
          [key]: newData,
        },
      })
    }
  }
  const CalendarInputChange = ({
    key,
    id,
    range,
    unit,
    val,
    type,
    data = {},
  }: InputChangeProps & { data?: any }) => {
    const updatedItem = data[type][key]?.map((v) =>
      v?.id === id
        ? {
            ...v,
            [range]: {
              ...v[range],
              hours: unit === "hours" ? val : v[range]["hours"],
              minutes:
                unit === "minutes"
                  ? val
                  : val
                    ? v[range]["minutes"] || "00"
                    : "",
            },
          }
        : v,
    )
    return {
      ...data,
      [type]: {
        ...data[type],
        [key]: updatedItem,
      },
    }
  }

  const handleCalendarInputChange = ({ ...params }: InputChangeProps) => {
    if (activeTab === "bulk_setting_for_year") {
      const newData = CalendarInputChange({
        ...params,
        data: yearlyCalendarData,
      })
      setYearlyCalendarData(newData)
    }
    if (activeTab === "bulk_setting_for_month") {
      const newData = CalendarInputChange({
        ...params,
        data: monthlyCalendarData,
      })
      setMonthlyCalendarData(newData)
    }
  }
  const handleCalendarOtherInputChange = ({ key, val }: InputChangeProps) => {
    if (activeTab === "bulk_setting_for_year") {
      setYearlyCalendarData({
        ...yearlyCalendarData,
        [key]: val,
      })
    }
    if (activeTab === "bulk_setting_for_month") {
      setMonthlyCalendarData({
        ...monthlyCalendarData,
        [key]: val,
      })
    }
  }
  const onApply = () => {
    if (activeTab === "bulk_setting_for_year") {
      const { business_data, service_hours } = handleYearlyDataApplied({
        yearlyCalendarData,
        operationOptionsValues,
      })
      if (
        Object.keys(business_data)?.length ||
        Object.keys(service_hours)?.length
      ) {
        setYearlyCalendarData({
          ...yearlyCalendarData,
          business_hours: {
            ...yearlyCalendarData?.business_hours,
            ...business_data,
          },
          service_hours: {
            ...yearlyCalendarData?.service_hours,
            ...service_hours,
          },
          holidays: operationOptionsValues?.settings?.holidays || [],
        })
      }
    }
    if (activeTab === "bulk_setting_for_month") {
      const { business_data, service_hours } = handleMonthlyDataApplied({
        monthlyCalendarData,
        operationOptionsValues,
      })

      if (
        Object.keys(business_data)?.length ||
        Object.keys(service_hours)?.length
      ) {
        setMonthlyCalendarData({
          ...monthlyCalendarData,
          business_hours: {
            ...monthlyCalendarData?.business_hours,
            ...business_data,
          },
          service_hours: {
            ...monthlyCalendarData?.service_hours,
            ...service_hours,
          },
          holidays: operationOptionsValues?.settings?.holidays || [],
          meal: operationOptionsValues?.settings?.meal || null,
        })
      }
    }
    scrollToSelectedElement(listRef)
  }
  const toggleHolidayAndMeal = (data) => {
    if (activeTab === "bulk_setting_for_year") {
      const modifiedData = yearlyCalendarData?.business_hours[data?.key]?.map(
        (v) => ({
          ...v,
          [data?.type]: data?.value ? "1" : "0",
        }),
      )
      setYearlyCalendarData({
        ...yearlyCalendarData,
        business_hours: {
          ...yearlyCalendarData?.business_hours,
          [data?.key]: modifiedData,
        },
      })
    }
    if (activeTab === "bulk_setting_for_month") {
      const modifiedData = monthlyCalendarData?.business_hours[data?.key]?.map(
        (v) => ({
          ...v,
          [data?.type]: data?.value ? "1" : "0",
        }),
      )

      setMonthlyCalendarData({
        ...monthlyCalendarData,
        business_hours: {
          ...monthlyCalendarData?.business_hours,
          [data?.key]: modifiedData,
        },
      })
    }
  }
  const onPagination = (date: Dayjs) => {
    serSearchParams({
      ...searchParams,
      date,
    })
    setOperationOptionsValues({
      ...operationOptionsValues,
      params: {
        ...operationOptionsValues?.params,
        date,
      },
    })
    updateSearchParams(date)
  }

  //fetch data
  const { isLoading, isFetching } = useQuery({
    queryKey: ["businessDayRegistrationData", searchParams, activeTab],
    queryFn: () =>
      fetchBusinessDayRegistration({
        facilityId: +searchParams?.facility_id,
        currentYear: dayjs(searchParams?.date)?.year(),
        currentMonth: dayjs(searchParams?.date)?.month() + 1,
        tab: activeTab,
      }),
    onSuccess: (response) => {
      if (activeTab === "bulk_setting_for_year") {
        const data = response?.data[0] || {}
        const finalData = serializeYearlyBusinessDayManagementDataResponse({
          data,
          defaultData: yearlyInitialValues || {},
        })
        setYearlyCalendarData(finalData)
        setOriginalData({
          ...originalData,
          yearly: response?.data,
        })
        return response
      }
      if (activeTab === "bulk_setting_for_month") {
        const data = response?.data || []
        const finalData: any =
          serializeMonthlyBusinessDayManagementDataResponse({
            data: data || [],
            defaultData: monthInitialValues || {},
            date: operationOptionsValues?.params?.date,
          })
        setMonthlyCalendarData({
          ...finalData,
          override_facilities:
            finalData?.override_facilities ||
            monthlyCalendarData?.override_facilities,
        })
        setOriginalData({
          ...originalData,
          monthly: response?.data?.business_days,
        })
        return response
      }
    },
    refetchOnWindowFocus: false,
  })

  //create/update data
  const { isLoading: isAdding, mutate } = useMutation({
    mutationFn: registerBusinessDay,
    onSuccess: () => {
      showToast({
        type: "success",
        message: t("Business Days") + t("Updated Successfully"),
      })
      setTimeout(() => {
        router.push("/business-day-management")
      }, 1000)
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      showToast({
        type: "error",
        message: msg
          ? t(msg)
          : t("Something went wrong. Please contact administrator"),
      })
    },
  })
  const handleSave = () => {
    const year = +dayjs(operationOptionsValues?.params?.date).year()
    const month = +dayjs(operationOptionsValues?.params?.date).month()
    if (activeTab === "bulk_setting_for_year") {
      const data = serializeYearlyBusinessDayManagementDataPayload({
        yearlyCalendarData,
        operationOptionsValues,
        year,
      })
      mutate({ values: data, tab: activeTab })
    }
    if (activeTab === "bulk_setting_for_month") {
      const data = serializeMonthlyBusinessDayManagementDataPayload({
        monthlyCalendarData,
        year,
        month,
        operationOptionsValues,
      })
      mutate({ values: data, tab: activeTab })
    }
  }

  //redirect to list page if no year,month and facility id available in query params
  if (!facilityId && !year && !month) {
    router?.replace("/business-day-management")
    return
  }

  const facilityType = facilities?.find((v) => v?.value === facilityId)
    ?.facility_type

  return (
    <BusinessDayManagementContext.Provider
      value={{
        loading: true,
        initialHours,
        operationOptionsValues: operationOptionsValues,
        onApply,
        listRef,
        activeTab: activeTab,
        setActiveTab: (tab) => {
          setActiveTab(tab)
          updateParams(
            {
              ...router?.query,
              tab,
            },
            "/business-day-management/registration",
          )
        },
        handleOperationInputChange,
        onDisplayChangeBtnClicked,
        handleOperationInputHourChange,
        //yearly calendar values
        handleDataAddition,
        yearlyBusinessHours: yearlyCalendarData?.business_hours || [],
        yearlyServiceHours: yearlyCalendarData?.service_hours || [],
        handleDataRemove,
        handleCalendarInputChange,
        handleCalendarOtherInputChange,
        yearlyCalendarData,
        //monthly calendar values
        monthlyBusinessHours: monthlyCalendarData?.business_hours || [],
        monthlyServiceHours: monthlyCalendarData?.service_hours || [],
        monthlyCalendarData,
        onPagination,
        isDataLoading: isLoading || isFetching,
        handleSave,
        isAdding,
        originalData,
        toggleHolidayAndMeal,
        facilityType: facilityType,
      }}
    >
      {children}
    </BusinessDayManagementContext.Provider>
  )
}
const useBusinessDayManagement = () => {
  const context = React.useContext(BusinessDayManagementContext)
  if (context === undefined) {
    throw new Error(
      "BusinessDayManagementContext must be used within a BusinessDayManagementProvider",
    )
  }
  return context
}
export { BusinessDayManagementProvider, useBusinessDayManagement }
