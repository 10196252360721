import { getWeekNameByDate } from "@project/common"
import dayjs from "dayjs"
import arraySupport from "dayjs/plugin/arraySupport"
import { useRouter } from "next/router"
import React, { useMemo, useState } from "react"
import { useQuery } from "react-query"
import { FULL_DATE_FORMAT_EN } from "../constants"
import {
  useFetUsersContractFacility,
  useFetchFacilitiesWithConfirmedBilling,
  useFetchMealMaster,
  useFetchOneUser,
} from "../hooks/useFetchData"
import { getUserMonthFacilitySchedule } from "../services"
dayjs.extend(arraySupport)
const ApplicationManagementContext = React.createContext<{
  params?: any
  isLoading?: boolean
  setParams: React.Dispatch<React.SetStateAction<any>>
  dataSource?: any[]
  user_id?: string
  handleDataChange: ({
    date,
    key,
    value,
  }: {
    date: string
    key: string
    value: string | boolean
  }) => void
  response?: any
  onReflect?: (values: any) => void
  onResetForm?: () => void
  contractFacilities?: any
  user?: any
  hasMonthlySchedule?: boolean
  facilitiesWithConfirmedBilling?: any
  mealData?: any[]
}>(undefined)

const INITIAL_DATA = {
  facility_id: "",
  user_id: "",
  date: null,
  shift_id: null,
  meal_flag: false,
  meal_id: null,
  goto_meet_flag: false,
  goto_meet_place: null,
  goto_meet_place_other: null,
  goto_meet_time: null,
  take_flag: false,
  take_place: null,
  take_place_other: "",
  take_time: null,
  remarks: "",
  is_applied: true,
  facility: {},
  attendance_shift: {},
  meal: {
    id: null,
    name: "",
    cost: null,
  },
  holiday: "0",
  isCanceled: false,
}

const ApplicationManagementProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const router = useRouter()
  const { user_id, year, month } = router?.query as any
  const [dataSource, setDataSource] = useState([])
  const [params, setParams] = useState({
    year: +year || dayjs()?.year(),
    month: +month || dayjs()?.month() + 1,
    user_id: user_id || null,
    display_settings: [],
    display_settings_calender: ["3", "4", "10", "5", "6", "7", "8", "9"],
  })
  const { data: user } = useFetchOneUser(user_id)
  const { userContractData } = useFetUsersContractFacility(user_id, true)

  const { mealData } = useFetchMealMaster({
    page: 1,
    pageSize: "Infinity",
    facilityIds: user?.data?.active_contract?.facility_id,
    enabled: !!user?.data?.active_contract?.facility_id,
  })
  const { facilitiesWithConfirmedBilling } =
    useFetchFacilitiesWithConfirmedBilling({
      year: params?.year,
      month: params?.month,
      facilityIds: user?.data?.active_contract?.facility_id,
    })

  useMemo(() => {
    const date = dayjs([params?.year, params?.month - 1, 1])
    const initialData = Array.from(
      { length: dayjs(date).daysInMonth() },
      (_, index) => {
        const currentDate = dayjs([
          dayjs(date).year(),
          dayjs(date).month(),
          index + 1,
        ])
        const week_day = currentDate.day()
        return {
          day: index + 1,
          week: getWeekNameByDate(week_day),
          week_day,
          ...INITIAL_DATA,
          facility_id: userContractData?.[1]?.value,
          date: dayjs(currentDate).format(FULL_DATE_FORMAT_EN),
          user_id: user_id,
        }
      },
    )
    setDataSource(initialData || [])
  }, [params?.year, params?.month])

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["user-application-management", params],
    queryFn: () =>
      getUserMonthFacilitySchedule({
        user_id: params?.user_id,
        year: +params?.year,
        month: +params?.month,
      }),
    onSuccess: (res) => {
      const data = dataSource?.map((source) => {
        const schedule = res?.data?.monthly_schedule?.find(
          (schedule) => schedule?.date === source.date,
        )
        const ids = {
          shift_id: schedule?.shift_id ? String(schedule?.shift_id) : null,
          meal_id: schedule?.meal_id ? String(schedule?.meal_id) : null,
        }
        if (schedule) {
          return {
            ...source,
            ...schedule,
            facility_id: schedule?.facility?.id || userContractData?.[1]?.value,
            meal_flag: schedule?.meal_flag ? true : false,
            take_flag: schedule?.take_flag ? true : false,
            goto_meet_place: schedule?.goto_meet_place
              ? String(schedule?.goto_meet_place)
              : schedule?.goto_meet_place,
            take_place: schedule?.take_place
              ? String(schedule?.take_place)
              : schedule?.take_place,
            is_applied: schedule?.is_applied ? true : false,
            ...ids,
          }
        }
        return {
          ...source,
          facility_id: userContractData?.[1]?.value,
          meal_flag: schedule?.meal_flag ? true : false,
          goto_meet_place: schedule?.goto_meet_place
            ? String(schedule?.goto_meet_place)
            : schedule?.goto_meet_place,
          take_place: schedule?.take_place
            ? String(schedule?.take_place)
            : schedule?.take_place,
          take_flag: schedule?.take_flag ? true : false,
          is_applied: schedule?.is_applied ? true : false,
          ...ids,
        }
      })

      setDataSource(data)
    },
    enabled: !!params?.user_id,
    refetchOnWindowFocus: false,
  })

  const handleDataChange = ({
    date,
    key,
    value,
  }: {
    date: string
    key: string
    value: string
  }) => {
    const newData = dataSource?.map((source) => {
      if (key === "facility_id") {
        return source?.day === date
          ? { ...source, is_applied: true, [key]: value }
          : source
      }
      if (key == "cancel") {
        return source?.day === date
          ? {
              ...source,
              shift_id: null,
              meal_flag: false,
              meal_id: null,
              goto_meet_flag: false,
              goto_meet_place: null,
              goto_meet_place_other: null,
              goto_meet_time: null,
              take_flag: false,
              take_place: null,
              take_place_other: "",
              take_time: null,
              remarks: "",
              is_applied: false,
            }
          : source
      }
      return source?.day === date
        ? { ...source, [key]: value, is_applied: true }
        : source
    })

    setDataSource(newData)
  }

  const onReflect = ({ day_of_week, ...rest }: any) => {
    const data = dataSource?.map((source) => {
      const day = dayjs(source?.date)?.day()
      return (day !== 6 && day_of_week?.includes(String(day))) ||
        (day !== 0 && day_of_week?.includes(String(day)))
        ? {
            ...source,
            ...rest,
            date: source?.date,
            goto_meet_place: rest?.goto_meet_place
              ? String(rest?.goto_meet_place)
              : rest?.goto_meet_place,
            take_place: rest?.take_place
              ? String(rest?.take_place)
              : rest?.take_place,
            meal_flag: rest?.meal_flag ? true : false,
            meal_id: rest?.meal_flag,
          }
        : source
    })
    setDataSource(data)
  }

  const onResetForm = () => {
    const newData = dataSource?.map((source) => {
      return {
        ...source,
        shift_id: null,
        meal_flag: false,
        meal_id: null,
        goto_meet_flag: false,
        goto_meet_place: null,
        goto_meet_place_other: null,
        goto_meet_time: null,
        take_flag: false,
        take_place: null,
        take_place_other: "",
        take_time: null,
        remarks: "",
        is_applied: false,
      }
    })
    setDataSource(newData)
  }

  return (
    <ApplicationManagementContext.Provider
      value={{
        dataSource,
        params,
        setParams,
        isLoading: isFetching || isLoading,
        user_id,
        handleDataChange,
        response,
        onReflect,
        onResetForm,
        contractFacilities: userContractData,
        user,
        hasMonthlySchedule: response?.data?.monthly_schedule?.filter(
          (schedule) => schedule?.is_applied,
        ).length
          ? true
          : false,
        facilitiesWithConfirmedBilling,
        mealData: mealData?.data || [],
      }}
    >
      {children}
    </ApplicationManagementContext.Provider>
  )
}
const useApplicationManagement = () => {
  const context = React.useContext(ApplicationManagementContext)
  if (context === undefined) {
    throw new Error(
      "ApplicationManagementContext must be used within a ApplicationManagementContextProvider",
    )
  }
  return context
}

export { ApplicationManagementProvider, useApplicationManagement }
