import React, { createContext, useState } from "react"
import { useMutation, useQuery } from "react-query"
import {
  createOrUpdateAssessmentSetting,
  getAllAssessmentSettings,
} from "../services/assessmentSettings.services"

import { useNotification } from "@project/common"
import { useTranslation } from "react-i18next"
import { getAssessmentPayload } from "../serialize"

type AssessmentSettingsLContextProps = {
  assessmentData?: any
  isLoading?: boolean
  isReFetching?: boolean
  toggleSorting: (values: {
    settingId: number
    categoryId?: number
    isSorting: boolean
  }) => void
  handleCategorySort: (values: {
    settingId: number
    categoryId?: number
    dir: "up" | "down"
  }) => void
  handleCategoryItemSort: (values: {
    settingId: number
    categoryId: number
    itemId: number
    dir: "up" | "down"
  }) => void
  saveAssessmentSortData: (assessmentId: number) => void
  currentlySortingDataInfo: any
  isMutating: boolean
  handleHiddenItemList: (value: any[]) => void
  refetch: any
}
const AssessmentSettingsContext = createContext<
  Partial<AssessmentSettingsLContextProps>
>({})
const AssessmentSettingsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { t } = useTranslation()
  const { showToast } = useNotification()
  const [assessmentData, setAssessmentData] = useState<any>([])
  const [currentlySortingDataInfo, setCurrentlySortingDataInfo] = useState({
    settingId: null,
    categoryId: null,
    isSorting: false,
  })
  const { isLoading, refetch, isFetching } = useQuery({
    queryKey: ["assessment-master-list"],
    queryFn: () => getAllAssessmentSettings(),
    onSuccess: (res) => {
      const isEditAble = (
        settingTitle: string,
        categoryTitle: string,
        itemTitle: string,
      ) => {
        if (
          settingTitle === "work" &&
          categoryTitle === "career" &&
          itemTitle === "employment history/training history, etc."
        ) {
          return false
        }
        if (
          settingTitle === "work" &&
          categoryTitle === "hope" &&
          itemTitle === "disclosure of disability information"
        ) {
          return false
        }
        return true
      }
      const data = res?.data?.map((settings) => ({
        ...settings,
        assessment_category: settings?.assessment_category?.map((category) => ({
          ...category,
          assessment_item: category?.assessment_item?.map((item) => ({
            ...item,
            editable:
              settings?.title?.toLowerCase() === "work"
                ? isEditAble(
                    settings?.title?.toLowerCase(),
                    category?.title?.toLowerCase(),
                    item?.title?.toLowerCase(),
                  )
                : true,
          })),
        })),
      }))

      setAssessmentData(data || [])
    },
    refetchOnWindowFocus: false,
  })
  //save assessment settings
  const { mutate, isLoading: isMutating } = useMutation({
    mutationFn: createOrUpdateAssessmentSetting,
    onSuccess: () => {
      showToast({
        type: "success",
        message: t("Updated Successfully"),
      })
      setCurrentlySortingDataInfo({
        settingId: null,
        categoryId: null,
        isSorting: false,
      })
      refetch()
    },
    onError: () => {
      showToast({
        type: "error",
        message: t("Something went wrong. Please contact administrator"),
      })
    },
  })

  const toggleSorting = ({
    settingId,
    categoryId,
    isSorting = true,
  }: {
    settingId: number
    categoryId?: number
    isSorting
  }) => {
    const newData = assessmentData?.map((assessment) =>
      assessment?.id === settingId
        ? {
            ...assessment,
            isSorting: !categoryId ? isSorting : assessment?.isSorting,
            assessment_category: categoryId
              ? assessment?.assessment_category?.map((category) =>
                  category?.id === categoryId
                    ? { ...category, isSorting }
                    : category,
                )
              : assessment?.assessment_category,
          }
        : assessment,
    )
    setCurrentlySortingDataInfo({
      settingId: isSorting ? settingId : null,
      categoryId: isSorting ? categoryId : null,
      isSorting,
    })
    setAssessmentData(newData || [])
  }

  const handleCategorySort = (values: {
    settingId: number
    categoryId: number
    dir: "up" | "down"
  }) => {
    const newData = assessmentData?.map((assessment) => {
      const sleetedCategoryIndex = assessment?.assessment_category?.findIndex(
        (category) => category?.id === values?.categoryId,
      )
      if (assessment?.id === values?.settingId) {
        if (sleetedCategoryIndex !== -1) {
          const indexOfItemToSwapWith =
            values?.dir === "up"
              ? sleetedCategoryIndex - 1
              : sleetedCategoryIndex + 1
          const assessment_category = assessment?.assessment_category
            ? [...assessment?.assessment_category]
            : []
          assessment_category[indexOfItemToSwapWith] =
            assessment_category.splice(
              sleetedCategoryIndex,
              1,
              assessment_category[indexOfItemToSwapWith],
            )[0]
          return { ...assessment, assessment_category }
        }
        return assessment
      }
      return assessment
    })
    setAssessmentData(newData)
  }
  const handleCategoryItemSort = (values: {
    settingId: number
    categoryId: number
    itemId: number
    dir: "up" | "down"
  }) => {
    const newData = assessmentData?.map((assessment) => {
      if (assessment?.id === values?.settingId) {
        const assessment_category = assessment?.assessment_category?.map(
          (category) => {
            if (category?.id === values?.categoryId) {
              const sleetedCategoryItemIndex =
                category?.assessment_item?.findIndex(
                  (item) => item?.id === values?.itemId,
                )
              const indexOfItemToSwapWith =
                values?.dir === "up"
                  ? sleetedCategoryItemIndex - 1
                  : sleetedCategoryItemIndex + 1
              const assessment_item = category?.assessment_item
                ? [...category?.assessment_item]
                : []
              assessment_item[indexOfItemToSwapWith] = assessment_item.splice(
                sleetedCategoryItemIndex,
                1,
                assessment_item[indexOfItemToSwapWith],
              )[0]
              return { ...category, assessment_item }
            }
            return category
          },
        )
        return {
          ...assessment,
          assessment_category,
        }
      }
      return assessment
    })
    setAssessmentData(newData || [])
  }
  const handleHiddenItemList = (list: any[]) => {
    setAssessmentData(list)
  }
  const saveSortData = (assessmentId: number) => {
    const categories = assessmentData?.find(
      (assessment) => assessment.id === assessmentId,
    )?.assessment_category
    const payload = getAssessmentPayload(categories || [])
    mutate({ values: payload, settingId: assessmentId })
  }

  return (
    <AssessmentSettingsContext.Provider
      value={{
        assessmentData: assessmentData || [],
        isLoading: isLoading,
        isReFetching: isFetching,
        toggleSorting,
        handleCategorySort,
        handleCategoryItemSort,
        saveAssessmentSortData: saveSortData,
        currentlySortingDataInfo,
        isMutating: isMutating,
        handleHiddenItemList,
        refetch,
      }}
    >
      {children}
    </AssessmentSettingsContext.Provider>
  )
}
//use AssessmentSettingsListContext

const UseAssessmentSettings = () => {
  const context = React.useContext(AssessmentSettingsContext)
  if (context === undefined) {
    throw new Error(
      "AssessmentSettingsContext must be used within a AssessmentSettingsContext",
    )
  }
  return context
}
export { AssessmentSettingsProvider, UseAssessmentSettings }
