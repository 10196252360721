import dayjs, { Dayjs } from "dayjs"
import {
  BUSINESS_HOURS_INITIAL,
  BUSINESS_REGISTRATION_SUBJECTED_OCCUPATION,
  WEEK_DAYS,
} from "../constants"
import {
  BUSINESS_HOURS,
  HOURS_INITIAL,
  INITIAL_CALENDAR_DATA,
  INITIAL_CALENDAR_DATA_EXTRA,
} from "../types"
const addNewBusinessOrServiceHours = (
  originalArray: any[],
  hrs2: any,
  hrs3: any,
) => {
  const defaultArray = []
  if (hrs2?.from?.hours && originalArray?.length < 2) {
    defaultArray.push({
      ...hrs2,
      id: 2,
    })
  }
  if (hrs3?.from?.hours && originalArray?.length < 3) {
    defaultArray.push({
      ...hrs3,
      id: 3,
    })
  }

  return defaultArray || []
}
//yearly data manipulation
const getYearlyHoursData = ({
  operationOptionsValues,
  weekValue,
  weekKey,
  hoursData,
  type,
}: {
  operationOptionsValues: any
  weekValue: string | number
  hoursData?: any
  weekKey?: string | number
  type: "business_hours" | "service_hours"
}) => {
  const holiday = operationOptionsValues?.settings?.holidays?.find(
    (v) => v === weekValue,
  )
  const day = operationOptionsValues?.settings?.business_days?.find(
    (v) => v === weekValue,
  )
  const defaultData = hoursData[`${weekKey}`] || []
  if (!holiday && day) {
    const data = addNewBusinessOrServiceHours(
      [...defaultData] || [],
      operationOptionsValues?.settings[`${type}2`] || {},
      operationOptionsValues?.settings[`${type}3`] || {},
    )
    defaultData.push(...data)
  }
  const results = defaultData?.map((defaultHours) => {
    const currentHours =
      operationOptionsValues?.settings[`${type}${defaultHours?.id}`]
    const data = {
      from: holiday
        ? {
            hours: defaultHours?.from?.hours,
            minutes: defaultHours?.from?.minutes,
          }
        : day
          ? {
              hours: currentHours?.from?.hours || defaultHours?.from?.hours,
              minutes:
                currentHours?.from?.minutes || defaultHours?.from?.minutes,
            }
          : {
              ...defaultHours?.from,
            },
      to: holiday
        ? {
            hours: defaultHours?.to?.hours,
            minutes: defaultHours?.to?.minutes,
          }
        : day
          ? {
              hours: currentHours?.to?.hours || defaultHours?.to?.hours,
              minutes: currentHours?.to?.minutes || defaultHours?.to?.minutes,
            }
          : {
              ...defaultHours?.to,
            },
    }

    return {
      ...data,
      id: defaultHours?.id,
      holiday: holiday ? "1" : day ? "0" : defaultHours?.holiday || "0",
      meal: day
        ? operationOptionsValues?.settings?.meal
        : defaultHours?.meal || "0",
    }
  })
  return results
}
//handle applied data
export const handleYearlyDataApplied = ({
  yearlyCalendarData,
  operationOptionsValues,
}: {
  yearlyCalendarData?: INITIAL_CALENDAR_DATA
  operationOptionsValues?: any
}) => {
  const business_data = {}
  const service_hours = {}
  if (
    operationOptionsValues?.settings?.business_days?.length ||
    operationOptionsValues?.settings?.holidays?.length
  ) {
    WEEK_DAYS.map((week) => {
      business_data[`${week?.key}`] = getYearlyHoursData({
        operationOptionsValues,
        weekValue: week?.value,
        weekKey: week?.key,
        type: "business_hours",
        hoursData: yearlyCalendarData?.business_hours,
      })
      service_hours[`${week?.key}`] = getYearlyHoursData({
        operationOptionsValues,
        weekValue: week?.value,
        weekKey: week?.key,
        type: "service_hours",
        hoursData: yearlyCalendarData?.service_hours,
      })
    })
  }
  return { business_data, service_hours }
}
//payload serialize
export const serializeYearlyBusinessDayManagementDataPayload = ({
  yearlyCalendarData,
  operationOptionsValues,
  year,
}: {
  yearlyCalendarData?: INITIAL_CALENDAR_DATA
  operationOptionsValues: any
  year?: number
}) => {
  const obj = {}
  Object.keys(yearlyCalendarData?.business_hours)?.map((key) => {
    obj[`${key}_meal`] = yearlyCalendarData?.business_hours[key][0]?.meal
    obj[`${key}_holiday`] =
      yearlyCalendarData?.business_hours[key][0]?.holiday === "1" ? "1" : "0"
    yearlyCalendarData?.business_hours[key]?.map((value, i) => {
      obj[`${key}_business_start_hour${i + 1}`] = value?.from?.hours
      obj[`${key}_business_start_minute${i + 1}`] = value?.from?.minutes
      obj[`${key}_business_end_hour${i + 1}`] = value?.to?.hours
      obj[`${key}_business_end_minute${i + 1}`] = value?.to?.minutes
    })
    yearlyCalendarData?.service_hours[key]?.map((value, i) => {
      obj[`${key}_service_start_hour${i + 1}`] = value?.from?.hours
      obj[`${key}_service_start_minute${i + 1}`] = value?.from?.minutes
      obj[`${key}_service_end_hour${i + 1}`] = value?.to?.hours
      obj[`${key}_service_end_minute${i + 1}`] = value?.to?.minutes
    })
  })
  const data = {
    ...obj,
    facility_id: +operationOptionsValues?.params?.facility_id,
    year,
    time_setting_flg: yearlyCalendarData?.time_setting,
    day_hours: yearlyCalendarData?.day_hours
      ? +yearlyCalendarData?.day_hours
      : null,
    day_minutes: yearlyCalendarData?.day_minutes
      ? +yearlyCalendarData?.day_minutes
      : null,
    week_hours: yearlyCalendarData?.week_hours
      ? +yearlyCalendarData?.week_hours
      : null,
    week_minutes: yearlyCalendarData?.week_minutes
      ? +yearlyCalendarData?.week_minutes
      : null,
  }
  return data
}

//response serialize
export const serializeYearlyBusinessDayManagementDataResponse = ({
  data,
  defaultData,
}: {
  data: any
  defaultData: BUSINESS_HOURS
}) => {
  const handleHours = (type: string) => {
    const calendarHours = {}
    Object.keys(defaultData)?.map((key) => {
      const hours = []
      Array.from({ length: 3 }, (_, idx) => {
        const startHour = data[`${key}_${type}_start_hour${idx + 1}`]
        const startMinutes = data[`${key}_${type}_start_minute${idx + 1}`]
        const endHour = data[`${key}_${type}_end_hour${idx + 1}`]
        const endMinutes = data[`${key}_${type}_end_minute${idx + 1}`]
        if (startHour || startMinutes || endHour || endMinutes) {
          hours.push({
            id: idx + 1,
            from: {
              hours: startHour ?? null,
              minutes: startMinutes ?? null,
            },
            to: {
              hours: endHour ?? null,
              minutes: endMinutes ?? null,
            },
            meal: data[`${key}_meal`] || "0",
            holiday: data[`${key}_holiday`] || "",
          })
        }
      })
      calendarHours[`${key}`] = hours?.length
        ? hours
        : [
            {
              id: 1,
              ...BUSINESS_HOURS_INITIAL,
              meal: data[`${key}_meal`] || "0",
              holiday: data[`${key}_holiday`] || "",
            },
          ]
    })

    return calendarHours
  }
  const finalData = {
    business_hours: handleHours("business") || {},
    service_hours: handleHours("service") || {},
    time_setting: data?.time_setting_flg || "1",
    day_hours: data?.day_hours,
    day_minutes: data?.day_minutes,
    week_hours: data?.week_hours,
    week_minutes: data?.week_minutes,
  }
  return finalData
}

//****monthly data manipulation*****//
const getMonthlyHoursData = ({
  type,
  day,
  currentHours,
  operationOptionsValues,
}: {
  type: "business_hours" | "service_hours"
  day?: any
  currentHours: any[]
  operationOptionsValues?: any
}) => {
  const holiday = operationOptionsValues?.settings?.holidays?.includes(`${day}`)
  const businessDay = operationOptionsValues?.settings?.business_days?.includes(
    `${day}`,
  )
  const defaultHoursData = currentHours || []
  if (!holiday && businessDay) {
    const newData = addNewBusinessOrServiceHours(
      currentHours,
      operationOptionsValues?.settings[`${type}2`],
      operationOptionsValues?.settings[`${type}3`],
    )
    defaultHoursData?.push(...newData)
  }

  const result = currentHours?.map((hours: HOURS_INITIAL) => {
    const hourData = operationOptionsValues?.settings[`${type}${hours?.id}`]
    const data = {
      from: holiday
        ? {
            ...hours?.from,
          }
        : businessDay
          ? {
              hours: hourData?.from?.hours || hours?.from?.hours || "",
              minutes: hourData?.from?.minutes || hours?.from?.minutes || "",
            }
          : { ...hours?.from },
      to: holiday
        ? {
            ...hours?.to,
          }
        : businessDay
          ? {
              hours: hourData?.to?.hours || hours?.to?.hours || "",
              minutes: hourData?.to?.minutes || hours?.to?.minutes || "",
            }
          : { ...hours?.to },
    }
    return {
      ...data,
      id: hours?.id,
      holiday: businessDay ? "0" : holiday ? "1" : hours?.holiday || "0",
      meal: businessDay
        ? operationOptionsValues?.settings?.meal
        : hours?.meal || "0",
    }
  })
  return result
}

//handle applied data
export const handleMonthlyDataApplied = ({
  monthlyCalendarData,
  operationOptionsValues,
}: {
  monthlyCalendarData?: INITIAL_CALENDAR_DATA & INITIAL_CALENDAR_DATA_EXTRA
  operationOptionsValues?: any
}) => {
  const business_data = {}
  const service_hours = {}
  if (
    operationOptionsValues?.settings?.business_days?.length ||
    operationOptionsValues?.settings?.holidays?.length
  ) {
    Array.from(
      {
        length: dayjs(operationOptionsValues?.params?.date).daysInMonth() || 31,
      },
      (_, idx) => {
        const year = dayjs(operationOptionsValues?.params?.date)?.year()
        const month = dayjs(operationOptionsValues?.params?.date)?.month()
        const day = dayjs([year, month, idx + 1])?.day()
        const currentBusinessHours =
          monthlyCalendarData?.business_hours[`${idx + 1}`]
        const currentServiceHours =
          monthlyCalendarData?.service_hours[`${idx + 1}`]
        business_data[`${idx + 1}`] = getMonthlyHoursData({
          currentHours: currentBusinessHours,
          operationOptionsValues,
          day,
          type: "business_hours",
        })
        service_hours[`${idx + 1}`] = getMonthlyHoursData({
          currentHours: currentServiceHours,
          operationOptionsValues,
          day,
          type: "service_hours",
        })
      },
    )
  }

  return {
    business_data,
    service_hours,
  }
}
//payload serialize
export const serializeMonthlyBusinessDayManagementDataPayload = ({
  monthlyCalendarData,
  year,
  month,
  operationOptionsValues,
}: {
  monthlyCalendarData: INITIAL_CALENDAR_DATA & INITIAL_CALENDAR_DATA_EXTRA
  year: number
  month: number
  operationOptionsValues: any
}) => {
  const business_days = []
  const monthly_hour_serialize = (key: string) => {
    const business = {}
    const service = {}
    monthlyCalendarData?.business_hours[key]?.map((value, idx) => {
      business[`business_start_hour${idx + 1}`] = value?.from?.hours
      business[`business_start_minute${idx + 1}`] = value?.from?.minutes
      business[`business_end_hour${idx + 1}`] = value?.to?.hours
      business[`business_end_minute${idx + 1}`] = value?.to?.minutes
    })
    monthlyCalendarData?.service_hours[key]?.map((value, idx) => {
      service[`service_start_hour${idx + 1}`] = value?.from?.hours
      service[`service_start_minute${idx + 1}`] = value?.from?.minutes
      service[`service_end_hour${idx + 1}`] = value?.to?.hours
      service[`service_end_minute${idx + 1}`] = value?.to?.minutes
    })
    return {
      ...business,
      ...service,
    }
  }
  Object.keys(monthlyCalendarData?.business_hours)?.map((key) => {
    const data = {
      day: dayjs([year, month, +key])?.format("YYYY-MM-DD"),
      meal: monthlyCalendarData?.business_hours[key][0]?.meal,
      holiday:
        monthlyCalendarData?.business_hours[key][0]?.holiday === "1"
          ? "1"
          : "0",
      ...monthly_hour_serialize(key),
    }
    business_days?.push(data)
  })
  const occupations = {}
  monthlyCalendarData?.occupations?.map((value) => {
    const selectedOne = BUSINESS_REGISTRATION_SUBJECTED_OCCUPATION?.find(
      (v) => v?.value === value,
    )
    occupations[`${selectedOne?.key}`] = value ? "1" : "0"
  })
  const data = {
    facility_ids: monthlyCalendarData?.override_facilities?.length
      ? monthlyCalendarData?.override_facilities?.map((v) => +v)
      : [+operationOptionsValues?.params?.facility_id],
    year,
    month: month + 1,
    business_days,
    occupations,
    time_setting: monthlyCalendarData?.time_setting,
    day_hours: monthlyCalendarData?.day_hours
      ? +monthlyCalendarData?.day_hours
      : null,
    day_minutes: monthlyCalendarData?.day_minutes
      ? +monthlyCalendarData?.day_minutes
      : null,
    week_hours: monthlyCalendarData?.week_hours
      ? +monthlyCalendarData?.week_hours
      : null,
    week_minutes: monthlyCalendarData?.week_minutes
      ? +monthlyCalendarData?.week_minutes
      : null,
    modified_working: monthlyCalendarData?.modified_working,
    modified_working_start_day: monthlyCalendarData?.modified_working_start_day
      ? +monthlyCalendarData?.modified_working_start_day
      : null,
    modified_working_end_day: monthlyCalendarData?.modified_working_end_day
      ? +monthlyCalendarData?.modified_working_end_day
      : null,
    modified_working_hours: monthlyCalendarData?.modified_working_hours
      ? +monthlyCalendarData?.modified_working_hours
      : null,
    modified_working_minutes: monthlyCalendarData?.modified_working_minutes
      ? +monthlyCalendarData?.modified_working_minutes
      : null,
  }

  return data
}
//response serialize
export const serializeMonthlyBusinessDayManagementDataResponse = ({
  data,
  defaultData,
  date,
}: {
  data: any
  defaultData: BUSINESS_HOURS
  date?: Dayjs | undefined
}) => {
  const handleHours = (type: string) => {
    const calendarHours = {}
    const year = dayjs(date).year()
    const month = dayjs(date).month()
    Object.keys(defaultData)?.map((key) => {
      const hours = []
      const item = data?.business_days?.find((value) => {
        const date = value?.day?.split("T")[0] || ""
        return date === dayjs([year, month, +key]).format(`YYYY-MM-DD`)
      })
      if (item) {
        Array.from({ length: 3 }, (_, idx) => {
          const startHour = item[`${type}_start_hour${idx + 1}`]
          const startMinutes = item[`${type}_start_minute${idx + 1}`]
          const endHour = item[`${type}_end_hour${idx + 1}`]
          const endMinutes = item[`${type}_end_minute${idx + 1}`]
          if (startHour || startMinutes || endHour || endMinutes)
            hours.push({
              id: idx + 1,
              from: {
                hours: startHour ?? null,
                minutes: startMinutes ?? null,
              },
              to: {
                hours: endHour ?? null,
                minutes: endMinutes ?? null,
              },
              meal: item[`meal`] || "0",
              holiday: item[`holiday`] || "0",
            })
        })
      }
      calendarHours[`${key}`] = hours?.length
        ? hours
        : [
            {
              id: 1,
              ...BUSINESS_HOURS_INITIAL,
              meal: item ? item?.[`meal`] : "0",
              holiday: item ? item?.[`holiday`] : "0",
            },
          ]
    })

    return calendarHours
  }

  const getOccupationData = () => {
    const values = []
    BUSINESS_REGISTRATION_SUBJECTED_OCCUPATION?.map((occupation) => {
      if (data?.full_time_staff_work[`${occupation.key}`]) {
        const value = BUSINESS_REGISTRATION_SUBJECTED_OCCUPATION?.find(
          (v) => v?.key === occupation?.key,
        )?.value
        values?.push(value)
      }
    })
    return values || []
  }

  const finalData = {
    business_hours: handleHours("business") || {},
    service_hours: handleHours("service") || {},
    time_setting: data?.full_time_staff_work?.time_setting || "1",
    day_hours: data?.full_time_staff_work?.day_hours,
    day_minutes: data?.full_time_staff_work?.day_minutes,
    week_hours: data?.full_time_staff_work?.week_hours,
    week_minutes: data?.full_time_staff_work?.week_minutes,
    modified_working: data?.full_time_staff_work?.modified_working || "0",
    modified_working_start_day:
      data?.full_time_staff_work?.modified_working_start_day,
    modified_working_end_day:
      data?.full_time_staff_work?.modified_working_end_day,
    modified_working_hours: data?.full_time_staff_work?.modified_working_hours,
    modified_working_minutes:
      data?.full_time_staff_work?.modified_working_minutes,
    occupations: getOccupationData(),
  }
  return finalData || {}
}
