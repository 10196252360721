import dayjs from "dayjs"

//converting response data into frontend required data
export const serializeAttendanceResponseInToInitialData = ({
  monthInitialData,
  responseData,
  params,
  initialValues,
}: {
  monthInitialData: any
  responseData: any
  params: any
  initialValues
}) => {
  const newObj = {}
  Object?.keys(monthInitialData)?.map((key) => {
    const currentDate = dayjs([
      params?.date.year(),
      params?.date?.month(),
      +key,
    ]).format("YYYY-MM-DD")
    const currentData = responseData?.find((v) => {
      const d = v?.date?.split("T")?.[0]
      return d === currentDate
    })
    if (currentData) {
      const start_time = currentData?.attendance_start_time?.split(":")
      const end_time = currentData?.attendance_end_time?.split(":")
      const start_time2 = currentData?.attendance_start_time2?.split(":")
      const end_time2 = currentData?.attendance_end_time2?.split(":")
      const matchedData = [
        {
          ...initialValues,
          attendance_start_time_hr: start_time?.[0] || "",
          attendance_start_time_min: start_time?.[1] || "",
          attendance_end_time_hr: end_time?.[0] || "",
          attendance_end_time_min: end_time?.[1] || "",
          attendance_rest_minits: currentData?.attendance_rest_minits || "",
          attendance_shift_id: currentData?.attendance_shift_id
            ? String(currentData?.attendance_shift_id)
            : null,
          date: currentData?.date,
          holiday_flag: currentData?.holiday_flag || "0",
          id: 1,
        },
      ]

      if (
        start_time2?.[0] &&
        start_time2?.[1] &&
        end_time2?.[0] &&
        end_time2?.[1]
      ) {
        matchedData.push({
          ...initialValues,
          attendance_start_time_hr2: start_time2?.[0] || "",
          attendance_start_time_min2: start_time2?.[1] || "",
          attendance_end_time_hr2: end_time2?.[0] || "",
          attendance_end_time_min2: end_time2?.[1] || "",
          attendance_rest_minits2: currentData?.attendance_rest_minits2 || "",
          attendance_shift_id: currentData?.attendance_shift_id
            ? String(currentData?.attendance_shift_id)
            : null,
          id: 2,
          holiday_flag: currentData?.holiday_flag || 0,
          date: currentData?.date,
        })
      }
      newObj[`${key}`] = matchedData
    } else {
      newObj[`${key}`] = monthInitialData[`${key}`]
    }
  })
  return newObj
}
export const deserializeFormDataIntoAPIPayload = ({
  data,
  params,
}: {
  data: any
  params: any
}) => {
  const year = dayjs(params?.date)?.year()
  const month = dayjs(params?.date)?.month()
  const newData = []
  Object?.keys(data)?.map((key) => {
    const obj = {}
    const start_time = `${
      data[`${key}`]?.[0]?.attendance_start_time_hr || ""
    }:${data[`${key}`]?.[0]?.attendance_start_time_min || ""}`
    const end_time = `${data[`${key}`]?.[0]?.attendance_end_time_hr || ""}:${
      data[`${key}`]?.[0]?.attendance_end_time_min || ""
    }`
    const start_time2 = `${
      data[`${key}`]?.[1]?.attendance_start_time_hr2 || ""
    }:${data[`${key}`]?.[1]?.attendance_start_time_min2 || ""}`
    const end_time2 = `${data[`${key}`]?.[1]?.attendance_end_time_hr2 || ""}:${
      data[`${key}`]?.[1]?.attendance_end_time_min2 || ""
    }`
    obj[`date`] = dayjs([year, month, +key]).format("YYYY-MM-DD")
    obj[`is_deleted`] = false
    obj[`staff_id`] = +params?.staff_id
    obj[`facility_id`] = +params?.facility_id
    obj[`attendance_shift_id`] =
      +data[`${key}`]?.[0]?.attendance_shift_id || null
    obj[`attendance_start_time`] = start_time
    obj[`attendance_end_time`] = end_time
    obj[`attendance_start_time2`] = start_time2
    obj[`attendance_end_time2`] = end_time2
    obj[`attendance_rest_minits`] =
      +data[`${key}`]?.[0]?.attendance_rest_minits || null
    obj[`attendance_rest_minits2`] =
      +data[`${key}`]?.[1]?.attendance_rest_minits2 || null
    newData?.push(obj)
  })
  return newData
}
export const getSelectedShiftDataOfAttendanceSchedule = ({
  shiftId,
  shiftData,
  initialValues,
}: {
  shiftId: string
  shiftData: any[]
  initialValues
}) => {
  const selectedShift = shiftData?.find((opt) => opt?.value === shiftId)
  const start_time = selectedShift?.attendance_start_time?.split(":")
  const end_time = selectedShift?.attendance_end_time?.split(":")
  const start_time2 = selectedShift?.attendance_start_time2?.split(":")
  const end_time2 = selectedShift?.attendance_end_time2?.split(":")
  const matchedData = [
    {
      ...initialValues,
      attendance_start_time_hr: start_time?.[0] || "",
      attendance_start_time_min: start_time?.[1] || "",
      attendance_end_time_hr: end_time?.[0] || "",
      attendance_end_time_min: end_time?.[1] || "",
      attendance_rest_minits: selectedShift?.attendance_rest_minits || "",
      attendance_shift_id: shiftId ? shiftId : null,
      id: 1,
    },
  ]
  if (
    start_time2?.[0] &&
    start_time2?.[1] &&
    end_time2?.[0] &&
    end_time2?.[1]
  ) {
    matchedData.push({
      ...initialValues,
      attendance_start_time_hr2: start_time2?.[0] || "",
      attendance_start_time_min2: start_time2?.[1] || "",
      attendance_end_time_hr2: end_time2?.[0] || "",
      attendance_end_time_min2: end_time2?.[1] || "",
      attendance_rest_minits2: selectedShift?.attendance_rest_minits2 || "",
      attendance_shift_id: shiftId ? shiftId : null,
      id: 2,
    })
  }
  return matchedData
}
