import { Excel } from "antd-table-saveas-excel"
import { useRouter } from "next/router"
import React, { createContext, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
interface createContextProps {
  downloadAbleDataOf: any
  setDownloadAbleDataOf: React.Dispatch<React.SetStateAction<any>>
  downloadXlsx: () => void
}
const WagesRevenueContext = createContext<createContextProps | undefined>(
  undefined,
)
const WagesRevenueProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { panel } = router?.query as {
    panel: string
  }
  const [downloadAbleDataOf, setDownloadAbleDataOf] = useState({
    tab: t("Overall revenue"),
    overall_revenue: {
      columns: [],
      dataSource: [],
    },
    facility_wise: {
      columns: [],
      dataSource: [],
    },
    business: {
      columns: [],
      dataSource: [],
    },
    project: {
      columns: [],
      dataSource: [],
    },
    employment: {
      columns: [],
      dataSource: [],
    },
    support: {
      columns: [],
      dataSource: [],
    },
  })

  const downloadXlsx = () => {
    const excel = new Excel()
    excel.setTHeadStyle({
      fontSize: 10,
      v: "center",
      h: "center",
      border: true,
      width: 200,
    })
    excel.setTBodyStyle({
      fontSize: 10,
      v: "center",
      h: "center",
      border: true,
      width: 300,
    })
    excel
      .addSheet(downloadAbleDataOf?.tab || panel)
      .addColumns(downloadAbleDataOf[panel].columns)
      .addDataSource(downloadAbleDataOf[panel].dataSource, {
        str2Percent: true,
      })
      .saveAs(`${downloadAbleDataOf?.tab}.xlsx`)
  }
  return (
    <WagesRevenueContext.Provider
      value={{
        downloadAbleDataOf,
        setDownloadAbleDataOf,
        downloadXlsx,
      }}
    >
      {children}
    </WagesRevenueContext.Provider>
  )
}
const useWagesRevenueContext = () => {
  const context = useContext(WagesRevenueContext)
  if (context === undefined) {
    throw new Error(
      "useWagesRevenueContext must be used within a useWagesRevenueContext",
    )
  }
  return context
}
export { useWagesRevenueContext, WagesRevenueProvider }
