import dayjs, { Dayjs } from "dayjs"

export const serializeAttendanceRecordPayload = () => {}

const getData = (currentData: any) => {
  return {
    id: currentData?.id || null,
    tempId: currentData?.tempId || new Date().getTime(),
    is_delete: currentData?.is_delete || null,
    facility_id: currentData?.facility_id || null,
    staff_id: currentData?.staff_id || null,
    date: currentData?.date || null,
    rest_hours: currentData?.rest_hours || null,
    rest_minits_flg: currentData?.rest_minits_flg || null,
    attendance_start_time: currentData?.attendance_start_time || null,
    attendance_end_time: currentData?.attendance_end_time || null,
    attendance_rest_minits: currentData?.attendance_rest_minits || null,
    attendance_type: currentData?.attendance_type || null,
    attendance_start_time2: currentData?.attendance_start_time2 || null,
    attendance_end_time2: currentData?.attendance_end_time2 || null,
    attendance_rest_minits2: currentData?.attendance_rest_minits2 || null,
    attendance_type2: currentData?.attendance_type2 || null,
    remarks: currentData?.remarks || null,
    holiday_flag: currentData?.holiday_flag,
    is_scheduled: currentData?.is_scheduled,
    hasAttendance_shift:
      currentData?.attendance_shift?.id || currentData?.attendance_shift_id
        ? true
        : false,
  }
}
const getInitialArrayValues = (date: Dayjs, defaultValues: any) => {
  const initialData = {}
  Array.from({ length: dayjs(date).daysInMonth() || 31 }, (_, index) => {
    initialData[`${index + 1}`] = [
      {
        ...defaultValues,
        date: dayjs([dayjs(date).year(), dayjs(date).month(), index + 1]),
      },
    ]
  })
  return initialData || {}
}
export const deSerializeAttendanceRecordPayload = (
  date: Dayjs,
  defaultValues: any,
  res: any[],
) => {
  // initial array data
  const data = getInitialArrayValues(date, defaultValues)
  const response = {}
  Object.keys(data)?.map((key) => {
    const currentData = res?.find(
      (v) =>
        v?.date ===
        dayjs(data[key][0]?.date).format("YYYY-MM-DD") + "T00:00:00Z",
    )
    if (currentData) {
      const keyData = [getData({ ...currentData, tempId: 1 })]
      if (
        currentData?.attendance_type2 ||
        currentData?.attendance_start_time2 ||
        currentData?.attendance_end_time2
      ) {
        keyData.push(getData({ ...currentData, tempId: 2 }))
      }
      response[key] = keyData
    } else {
      response[key] = data[key]
    }
  })
  return response
}
