export const getAssessmentPayload = (data: any[]) => {
  const finalData = data?.map((category, index) => ({
    assessment_setting_id: category?.assessment_setting_id,
    title: category?.title,
    order: index + 1,
    show_display: category?.show_display,
    assessment_item: category?.assessment_item?.map((item, index) => ({
      title: item?.title,
      order: index + 1,
      show_display: item?.show_display,
    })),
  }))
  return finalData
}
